import { Injectable } from '@angular/core';
import { ngxLoadingAnimationTypes } from "ngx-loading";
@Injectable()
export class Constants {
    public static USER_TYPE: any = {
        PIPELINE_USER: "PIPELINE_USER",
        SUPPLIER_USER: "SUPPLIER_USER",
        CUSTOMER_USER: "CUSTOMER_USER",
        BOTH_USER: "BOTH_USER"
    };

    public static CAUSED_BY_TYPE: any = {
        PIPELINE: "PIPELINE",
        SUPPLIER: "SUPPLIER",
        CUSTOMER: "CUSTOMER",
    };

    public static ORG_TYPES: any = {
        SUPPLIER: "SUPPLIER",
        CUSTOMER: "CUSTOMER",
        BOTH: "BOTH"
    };

    public static ADDRESS_TYPES: any = {
        INVOICE: "INVOICE",
        DELIVERY: "DELIVERY",
        CONTACT: "CONTACT"
    };

    public static FILE_SHARE_TYPE:any={
        ORIGINAL:'original'
    }

    public static PAPER_TYPE: any = {
        COVER_TYPE: "COVER",
        CONTENT_TYPE: "CONTENT",
        BOTH_TYPE: "BOTH",
        PAPER_TYPE:"PAPER"
    };
    public static MESSAGE_SIDE: any = {
        LEFT: "LEFT",
        RIGHT: "RIGHT"
    };
    public static JOB_TITLE: any = {
        ACCOUNT_MANAGER: "ACCOUNT_MANAGER",
        SALES_MANAGER: "SALES_MANAGER",
        PROJECT_MANAGER: "PROJECT_MANAGER"
    };
    public static ORG_TYPE: any = {
        CUSTOMER: "Customer",
        SUPPLIER: "Supplier",
        BOTH: "Both"
    };
    public static COMMUNICATION_MODES: any = {
        EMAIL_TYPE: "EMAIL",
        PHONE_TYPE: "PHONE",
    };
    public static PRINT_TYPES: any = {
        BROCHURE_TYPE: "BROCHURE",
        FOLDER_TYPE: "FOLDER",
        SHEET_TYPE: "SHEET",
        DISTRUBUTION_TYPE:"DISTRIBUTION",
        TRANSPORTATION_TYPE:"TRANSPORTATION",
        OTHERS_TYPE:"OTHERS",
        LOOSE_COUPON_PRINTING:"LOOSE_COUPON_PRINTING",
        GLUED_COUPON_PRINTING:"GLUED_COUPON_PRINTING"
    };
    public static PRODUCT_TYPES: any = {
        PRINT: "PRINT",
        OTHER: "OTHER",
        GENERAL: "GENERAL",
    };
    public static DAY_RULE: any = {
        DAYS_NET: "DAYS_NET",
        NEXT_MONTH_FIRST_DAY: "NEXT_MONTH_FIRST_DAY",
    };
    public static PURPOSE: any = {
        ORDER_TYPE: "ORDER",
        QUOTE_TYPE: "QUOTE",
    };
    public static SORT_BY_NOTES: any = {
        NEWEST: "NEWEST_FIRST",
        OLDEST: "OLDEST_FIRST",
    };
    public static SORT_BY_NOTIFICATION: any = {
        NEWEST: "NEWEST",
        OLDEST: "OLDEST",
    };
    public static FILTER_BY_NOTES: any = {
        ALL: "ALL",
        BY_ME: "BY_ME",
        BY_OTHER: "BY_OTHER",
        BY_ME_LABEL: "BY ME",
        BY_OTHER_LABEL: "BY OTHER",
        OTHERS: "OTHERS"
    };
    public static FILTER_BY_NOTIFICATION: any = {
        ALL: "ALL",
        RFQ: "RFQ",
        ORDER: "ORDER",
        PROFILE: "PROFILE"
    };
    public static NOTES_TYPE: any = {
        ALL: "ALL",
        CURRENT: "CURRENT",
        PAST: "PAST",
    };
    public static SUPPLIER_ROLES: any = {
        ADMIN: "ADMIN",
        USER: "USER",
    };
    public static MARGIN_TYPE: any = {
        BY_PERCENTAGE: "BY_PERCENTAGE",
        BY_VALUE: "BY_VALUE",
    };
    public static ORDER_TYPE: any = {
        VERSION: "VERSION",
        COPY: "COPY",
    };
    public static OPERATION_TYPE: any = {
        COPY: "COPY",
        VERSION: "VERSION",
        COMPLETED: "COMPLETED",
        REJECTED: "REJECTED",
        CONVERTED: "CONVERTED",
        VIEW: "VIEW",
        REPORT: "REPORT",
    }
    public static GROUP_TYPE: any = {
        SYSTEM: "SYSTEM",
        CUSTOM: "CUSTOM",
    }
    public static INVOICE_TYPE: any = {
        POST: "POST",
        EMAIL: "EMAIL",
    };

    public static DELIVERY_PACKAGING_TYPE: any = {
        IN_BOX: "IN_BOX",
        ON_PALLET: "ON_PALLET",
    };

    public static PRINTING_OPTION: any = {
        "OFFSET":"OFFSET",
        "DIGITAL":"DIGITAL",
        "WEB":"WEB",
        "LARGE_FORMAT":"LARGE_FORMAT",
    };

    public static BINDING_OPTION: any = {
        "GLUING":"GLUING",
        "SADDLE_STITCHING":"SADDLE_STITCHING",
        "PAD_GLUING":"PAD_GLUING",
        "THREAD_BINDING":"THREAD_BINDING",
    };


    public static TEMPLATE_OPTIONS:any = {
        "FROM_CUSTOMER_PRINT_READY_PDF_INCL_PIPELINE_ICC_PROFILES":"FROM_CUSTOMER_PRINT_READY_PDF_INCL_PIPELINE_ICC_PROFILES",
        "DOCUMENT_CONTROL_SCREEN_PROFFING_FROM_US":"DOCUMENT_CONTROL_SCREEN_PROFFING_FROM_US",
        "DOCUMENT_CONTROL_COLOR_SAMPLES_FROM_US":"DOCUMENT_CONTROL_COLOR_SAMPLES_FROM_US",
        "DOCUMENT_CONTROL_TEST_PRINT_INDIGO":"DOCUMENT_CONTROL_TEST_PRINT_INDIGO",
        "OTHERS":"OTHERS"
    }

    public static EXTRA_OPTION: any = {
        "CUTTING":"CUTTING",
        "FOLDING":"FOLDING",
        "LAMINATION":"LAMINATION",
        "ANGLE_PERFORATION":"ANGLE_PERFORATION",
        "HORIZONTAL_PERFORATION":"HORIZONTAL_PERFORATION",
        "HANDCOVER":"HANDCOVER",
        "HOLE_PUNCHING":"HOLE_PUNCHING",
        "PRESS_VARNISH":"PRESS_VARNISH",
        "LARGE_FORMAT_MOUNTING":"LARGE_FORMAT_MOUNTING",
        "CARDBOARD_BOTTOMED":"CARDBOARD_BOTTOM"
    };

    public static methods = {
        delete: 'delete',
        deactivate: 'deactivate',
        activate: 'activate',
        approve:'approve',
        reject:'reject'
    }
    public static PRINT_PRODUCT = 'PRINT_PRODUCT';
    public static OTHER_PRODUCT = 'OTHER_PRODUCT';
    public static DEFAULT_SELECTED_PAPER_NAME_CODE = 'SILK_COATED';
    public static ORIGINAL = 'ORIGINAL';
    public static PROCESSED = 'PROCESSED';
    public static LANG_CODE_EN = 'en';
    public static STATUS_CODE_0 = 0;
    public static STATUS_CODE_1 = 1;
    public static STATUS_CODE_2 = 2;
    public static STATUS_CODE_3 = 3;
    public static STATUS_CODE_4 = 4;
    public static STATUS_CODE_5 = 5;
    public static STATUS_CODE_6 = 6;
    public static STATUS_CODE_7 = 7;
    public static STATUS_CODE_8 = 8;
    public static validationTimeout = 1000;
    public static timeoutFiveSec = 5000;
    public static validationTimeout500 = 500;
    public static redirectionTimeout = 5000;
    public static oldPasswordCheckTrue = 1;
    public static oldPasswordCheckFalse = 0;
    public static forgotPasswordFlagTrue = 1;
    public static forgotPasswordFlagFalse = 0;
    public static EN = 'en';
    public static SV = 'sv';
    public static DEFAULT_LANG = 'en';
    public static TOKEN = 'token';
    public static ROW = 10;
    public static START = 1;
    public static PAGE = 0;
    public static MESSAGE_ROWS = 20;
    public static DEFAULT_VAT_PERCENTAGE = 25.00;
    public static RESPONSE_CODES: any = {
        SUCCESS_CODE : 200,
        SERVER_ERROR_CODE : 500,
        UNAUTHORIZED_ERROR_CODE : 401,
        NOT_FOUND_ERROR_CODE : 404,
        BAD_REQUEST_ERROR_CODE : 400,
        UNKNOWN_ERROR_CODE : 0
    };

    public static uploadedFileType: any = {
        MATERIAL_FILE : 'MATERIAL_FILE',
        COUPON_CODE_FILE : 'COUPON_CODE_FILE',
        PRINT_PROOF_FILE:'PRINT_PROOF_FILE',
        PRINT_PROOF_REPORT_FILE : 'PRINT_PROOF_REPORT_FILE',
        DISTRIBUTION_FILE : 'DISTRIBUTION_FILE',
        OTHER_FILE:'OTHER_FILE'
    }

    public static responseStatus: any = {
        SUCCESS : 'success',
        ERROR : 'error',
        WARNING:'warning',
        SUCCESS_1 : 'SUCCESS',
        ERROR_1 : 'ERROR',
        WARNING_1:'WARNING'
    }
    public static responseStatusMessage: any = {
        SUCCESS : 'Success',
        ERROR : 'Error'
    }
    public static requestType: any = {
        POST : 'POST',
        PUT : 'PUT',
        GET: 'GET',
        DELETE: 'DELETE',
    }
    public static validationType: any = {
        required: 'required',
        equalTo: 'equalTo',
        email: 'email',
        rangeLength: 'rangeLength',
        maxlength:'maxlength',
        minlength:'minlength',
        pattern: 'pattern'
    }
    public static resultCode:any = {
        INVALID_EMAIL:"INVALID_EMAIL",
        SUCCESS:"SUCCESS",
        INVALID_TOKEN:"INVALID_TOKEN",
        EXPIRED_TOKEN:"EXPIRED_TOKEN",
        INVALID_CREDENTIALS:"INVALID_CREDENTIALS",
        INVALID_OLD_PWD:"INVALID_OLD_PWD"
    }
    public static BOOLEAN_TRUE = true;
    public static BOOLEAN_FALSE = false;
    public static authToken = 'Auth-token';
    public static Authorization = 'Authorization';
    public static activate = 'ACTIVATE';
    public static deactivate = 'DEACTIVATE';
    public static acceptLanguage = 'ACCEPT_LANGUAGE';
    public static accept_language = 'Accept-Language';
    public static AUTH_TOKEN = 'AUTH_TOKEN';
    public static Cookie = 'cookie';
    public static setCookie = 'Set-Cookie';
    public static DEFAULT_CURRENCY_LOCALE='sv-SE';
    public static fieldTypes: any = {
        startRange: 4,
        endRange: 20,
        maxStringLength20 : 20,
        maxStringLength250 : 250,
        maxStringLength500 : 500,
        maxStringLength50 : 50,
        maxStringLength1000 : 1000,
        maxStringLength5000 : 5000,
        websiteURLPattern:"^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
        minForgotTokenLength32:'32',
        maxStringLength100:'100',
        maxStringLength4:'4'
    }
    public static PIPELINE_TITLE = ' | Pipeline Nordic AB';
    public static checkRoleAvailable(roles: any, role: any) {
        if(roles.indexOf(role) !== -1) {
          return true
        } else {
          return false
        }
    }
    public static PIPLINE_CAUSED_BY_TITLE = "Pipeline Nordic AB"
    public static datePlaceholder = 'YYYY-MM-DD';
    public static datePlaceholderforCalender = 'yy-mm-dd';
    public static yearRange ='2020:2099';
    public static timePlaceholder = 'HH:MM';
    public static fullDateTime = 'YYYY-MM-DD HH:mm';
    public static userSessionPipeline = 'pipelineSession';
    public static loginType = 'loginType';
    public static organizationIDType = 'organizationID';
    public static roleTypeSession = 'pipelineSessionRoleTypeData';
    public static sessionLocale = 'sessionLocale';
    public static loginOption = 'loginOption';
    public static singleRole = 'singleRole';
    public static text = 'text';
    public static password = 'password';

    public static ORDER_RFQ_ACTION_TYPE: any = {
        EDIT:'edit',
        COPY:'copy',
        VERSION:'version'
    }

    public static ORDER_RFQ_TYPE: any = {
        ORDER: 'ORDER',
        RFQ: 'RFQ',
        COPY_RFQ: 'COPY_RFQ',
        NEW_RFQ: 'NEW_RFQ',
        COPY: 'COPY',
        VERSION: 'VERSION',
        PARENT: 'PARENT',
        SUB_RFQ: 'SUB_RFQ',
        INDIVIDUAL: 'INDIVIDUAL',
        UPDATE: 'UPDATE',
        PUBLISH: "PUBLISH",
        ACCEPTANCE: "ACCEPTANCE",
        CONVERTED: "CONVERTED",
        NEW:"NEW",
        EDIT:"EDIT",
        REJECTED: "REJECTED",
        "PARENT_FINALIZE_RFQ": "PARENT_FINALIZE_RFQ",
        "FINALIZE_RFQ": "FINALIZE_RFQ",
        "CUSTOMER_ACCEPTED": "CUSTOMER_ACCEPTED",
        "CUSTOMER_ACCEPTANCE": "CUSTOMER_ACCEPTANCE",
    };
    public static RESPONSE_ERROR_MESSAGES: any = {
        MSG_ERROR_0: "Some error occured, Please try again after some time",
        MSG_ERROR_400: "Some error occured, Please try again after some time",
        MSG_ERROR_404: "Some error occured, Please try again after some time",
        MSG_ERROR_401: "You are not authorized for this page. Please check with your credential and login"
    };
    public static NOTIFICATION_INTERVAL = 50000;
    public static NOTIFICATION_COUNTER = false;
    public static CLEARON = "CLEARON";
    public static ONE_HOUR_LABEL = "1 Hour";
    public static TWO_HOUR_LABEL = "2 Hours";
    public static THREE_HOUR_LABEL = "3 Hours";
    public static FOUR_HOUR_LABEL = "4 Hours";
    public static DEFAULT_SELECT_OPTION = '-- Select --';
    public static SUPPLIER_EXPIRY_DATE = 'MMMM d, y HH:mm';
    public static DATE_FORMAT = 'yyyy-MM-dd';
    public static STANDART_DATE_FORMAT = 'yyyy-MM-DD';
    public static DATE_FORMAT_WITH_TIME = 'yyyy-MM-dd HH:mm';
    public static CURRENCY_VAL = 'SEK';
    public static ORDER_CURRENCY_VAL = 'SEK';
    public static PAGE_SIZE_VAL = 'mm';
    public static CURRENCY_POST_VAL = ',00';
    public static UNPUBLISHED = 'UNPUBLISHED';
    public static PUBLISHED = 'PUBLISHED';
    public static ACCEPTANCE = 'ACCEPTANCE';
    public static PUBLISH = 'PUBLISH';
    public static UNPUBLISH = 'UNPUBLISH';
    public static YES = 'yes';
    public static NO = 'no';
    public static FREE = 'FREE';
    public static TRUE = 'true';
    public static FALSE = 'false';
    public static EXPIRED = 'Expired';
    public static AUTOSUGGEST_DELIMITER = " - ";
    public static COMMA_DELIMITER = ",";
    public static SUCCESS_CODE = "200";
    public static COOKIE_NAME = "JSESSIONID";
    public static CONNECT_VERSION = "V 1.0";
    public static SESSION_NAME = "pipelineSession";
    public static CONTACT_US_MAIL = 'mailto:admin@pipelinenordic.se';
    public static NOTES_PAGE_LIMIT = 10;
    public static DOMAIN_NAME = window.location.hostname;

    public static ACCEPTED = 'ACCEPTED';
    public static APPROVED = 'APPROVED';
    public static PER_COPY = 'PER_COPY';
    public static UP_TO = 'UP_TO';
    public static PER_COPY_WITH_K_AND_M= 'PER_COPY_WITH_K_AND_M';
    public static PER_COPY_WITH_K_AND_M_AND_L = 'PER_COPY_WITH_K_AND_M_AND_L';
    public static INPUT='INPUT';
    public static COUPON_PRINTING_PAGE_WIDTH = 120;
    public static COUPON_PRINTING_PAGE_HEIGHT = 65;
    public static PAGE_WIDTH = 210;
    public static PAGE_HEIGHT = 297;
    public static DEFAULT_GLUED_COUPON_PRINTING_PAGE_COUNT = 25;

    // sandbox uat server link
    // public static SERVER_HOST = "https://devpipelineapps.56-north.com/pipeline/";
    // public static DEV_LINK = "https://coupons.pipelinenordic.se/";
    // "http://devconnectnew.56-north.com/"
    // public static SERVER_HOST = "http://devpipelineapps.56-north.com/pipeline/";
    // public static DEV_LINK = "http://devconnectnew.56-north.com/"
    // "http://devpipelineconnect.56-north.com/#/";

    //  LIVE link
    public static SERVER_HOST = "https://pipelinemsapi.pipelinenordic.se/";
  	public static DEV_LINK = "https://coupons.pipelinenordic.se/";
    // public static BASE_URL = Constants.SERVER_HOST + "api/"; //base url for server
    public static ROW_PER_PAGE = [10, 50, 100];
    public static FILE_SIZE_LIMIT = 5368709120;
    public static USERNAME_SEPERATOR = " ";
    public static DEFAULT_SELECT_VALUE = '';
    public static CURRENCY_CONVERSION_PARAMS = {prefix: '', thousands: '.', decimal: ',' ,align:'left', precision: 0};
    public static CURRENCY_CONVERSION_PARAMS_PRICE = {prefix: '', thousands: '.', decimal: ',' ,align:'left', precision: 0};
    public static CURRENCY_CONVERSION_PARAMS_PERCENTAGE = {prefix: '', thousands: '.', decimal: ',' ,align:'left', precision: 2};

    public static OFFER_LIST = 'offer-list';
    public static DELIVERY_LIST = 'delivery-list';
    public static ORDER_LIST = 'order-list';
    public static MARGIN_LIST = 'margin-list';
    public static INVOICE_LIST = 'invoice-list';
    public static ENTITY_SERVICE_URL='pipeline/api/v1/entityService/';
    public static MIDDLEWARE_SERVICE_URL='pipeline/api/v1/';
    public static RFQ_ORDER_SERVICE_URL='pipeline/api/v1/rfqOrderService/';
    public static GENERAL_SERVICE_URL = 'pipeline/api/v1/generalService/';
    public static FILE_SERVICE_URL = 'pipeline/api/v1/fileService/';
    
    public static REPORT_TYPES: any = {
        ORDER_LIST_REPORT:"ORDER_LIST_REPORT",
        OFFER_LIST_REPORT:"OFFER_LIST_REPORT",
        MARGIN_LIST_REPORT:"MARGIN_LIST_REPORT",
        DELIVERY_LIST_REPORT:"DELIVERY_LIST_PRODUCT",
        INVOICE_LIST_REPORT:"INVOICE_LIST_PRODUCT",
    }

    // URL CONSTANTS
    public static URLS: any = {
        'ORDERS':'/rfq-order/orders/list',
        'PIEPLINE_ORDERS':'/rfq-order/orders/list',
        'CUSTOMER_ORDERS':'/rfq-order/customer-orders/list',
        'SUPPLIER_ORDERS':'/rfq-order/supplier-orders/list',
        'FIRST_CHANGE_PWD':'entity/first-change-password',
        'LOGIN':'login',
        'INVALID_TOKEN':'entity/invalid-token',
        'EXPIRED_TOKEN':'entity/expire-token',
        'GROUP':'/rfq-order/supplier-group/',
        'PAPER':'/rfq-order/print-paper/',
        'PIPELINE_USER_MANAGEMENT':'/entity/pipeline-user/list',
        'CONTACT_PER_MANAGEMENT':'/entity/contact-person/list',
        'PRIT_PAPER_MGMT':'/rfq-order/print-paper/list',
        'ORG_MANAGEMENT':'/entity/organization/list',
        'SUPPLIER_GROUP_MGMT':'/rfq-order/supplier-group/list',
        'ORG_ADDRESS_LIST':'/entity/organization/:id/address/list',
        'EDIT_PIPELINE_PROFILE': '/entity/edit-pipeline-user-profile',
        'EDIT_SUPPLIER_PROFILE': '/entity/edit-supplier-profile',
        'EDIT_CUSTOMER_PROFILE': '/entity/edit-customer-profile',
        'MANAGE_ADDRESS': '/entity/organization-address/list',
        'EDIT_ORGANIZATION_PROFILE': 'entity/edit-organization-profile',
        'RFQS': '/rfq-order/rfqs/list',
        'RFQ_ORDER': 'rfq-order/',
        'SUPPLIER_RFQS': '/rfq-order/supplier-rfqs/list',
        'NOTIFICATIONS': 'notification',
        'COPY_ORDER':'/rfq-order/copy/ORDER/',
        'PUBLISHED_TAB':'/rfq-order/rfqs/list?tab_type=RFQ_PUBLISHED',
        'FREE_RFQ_TAB':'RFQ_FREE',
        'ACTIVE_TAB':'/rfq-order/orders/list?tab_type=ORDER_ACTIVE',
        'FREE_ORDER_TAB':'ORDER_FREE',
        'ADD_INCIDENT_REPORT': '/rfq-order/incident-reporting/add/',
        'EDIT_INCIDENT_REPORT': '/rfq-order/incident-reporting/edit/',
        'INCIDENT_REPORT': '/rfqs-order/incident-reporting/',
        'ADD_ORG':'/entity/organization/add',
        'CLOUDPROOF_PREVIEW':'file/cloudproof-preview',
        'REPORT_ORDER':'/report/order-list',
        'REPORT_OFFER':'/report/offer-list',
        'REPORT_MARGIN':'/report/margin-list',
        'REPORT_DELIVERY':'/report/delivery-list',
        'REPORT_INVOICE':'/report/invoice-list',
        'CHANGE_PASSWORD':'/entity/change-password',
        'BULK_INVOICE_GENERATE':'/rfq-order/orders/bulk-invoice-generate',
        'GENERATE_INVOICE':'/rfq-order/customer-invoice'
    };
    public static FILE_EXT: any = [
        "JPG",
        "jpeg",
        "gif",
        "png",
        "pdf",
        "PDF"
    ];
    public static BOX_IMAGES:any = {
        BLEED_BOX:'BleedBox',
        ART_BOX:'ArtBox',
        CROP_BOX:'CropBox',
        MEDIA_BOX:'MediaBox',
        TRIM_BOX:'TrimBox',
    }
    public static FILE_VIEW_STATUS:any = {
        VISIBLE: 'VISIBLE',
        HIDDEN: 'HIDDEN',
        PITSTOP_PROCESSED:'PITSTOP_PROCESSED',
        PITSTOP_OUTPUT_FILE_PREVIEW:'PITSTOP_OUTPUT_FILE_PREVIEW',
        PENDING_APPROVAL_PIPELINE: 'PENDING_APPROVAL_PIPELINE',
        PENDING_PIPELINE_APPROVAL: 'PENDING_PIPELINE_APPROVAL',
        APPROVED:'APPROVED',
        DECLINED:'DECLINED',
        PENDING_APPROVED:'PENDING_APPROVAL',
        PENDING_APPROVAL_CUSTOMER_END_CUSTOMER:'PENDING_APPROVAL_CUSTOMER_END_CUSTOMER',
        PENDING_APPROVAL_CUSTOMER:'PENDING_APPROVAL_CUSTOMER',
        UPLOADED: 'UPLOADED',
        PITSTOP_IN_PROGRESS: 'PITSTOP_IN_PROGRESS',
        PITSTOP_ERROR: 'PITSTOP_ERROR',
        PENDING_APPROVAL_CLEARON: 'PENDING_APPROVAL_CLEARON',
        PENDING_CLEARON_APPROVAL: 'PENDING_CLEARON_APPROVAL',
        PENDING_APPROVAL_PIPELINE_CUSTOMER: 'PENDING_APPROVAL_PIPELINE_CUSTOMER',
    }
    public static PDF_FILE_TYPE='application/pdf';
    public static downloadFileType: any = {
        PDF: 'pdf',
        XLSX: 'xlsx'
    }
    public static ACTION: any = {
        ADD:"add",
        EDIT: "edit",
        DELETE:"delete"
    };
    public static STATUS: any = {
        RFQ: {
            UNPUBLISHED: "UNPUBLISHED",
            PUBLISHED: "PUBLISHED",
            CONVERTED: "CONVERTED",
            EXPIRED: "EXPIRED",
            FINALIZED: "FINALIZED",
            REJECTED: "REJECTED",
            PARENT_INPROGRESS: "PARENT_INPROGRESS",
            PARENT_CONVERTED: "PARENT_CONVERTED",
            CUSTOMER_ACCEPTED: "CUSTOMER_ACCEPTED",
            CUSTOMER_REJECTED: "CUSTOMER_REJECTED",
            SUPPLIER_ACCEPTED: "SUPPLIER_ACCEPTED",
            SUPPLIER_REJECTED: "SUPPLIER_REJECTED",
            PARENT_FINALIZE_RFQ: "PARENT_FINALIZE_RFQ",
        },
        ORDER: {
            YET_TO_START: "YET_TO_START",
            DELIVERY_STARTED: "DELIVERY_STARTED",
            PRODUCTION_STARTED: "PRODUCTION_STARTED",
            DELIVERY_ENDED: "DELIVERY_ENDED",
            PRODUCTION_ENDED: "PRODUCTION_ENDED",
            SUPPLIER_COMPLETED: "SUPPLIER_COMPLETED",
            COMPLETED: "COMPLETED",
            CANCELLED: "CANCELLED",
        }
    }
    public static NOTIFICATION_MODULES: any = {
        RFQ: "RFQ",
        ORDER: "ORDER",
        ORGANISATION_PROFILE: "ORGANISATION_PROFILE",
        CONTACT_PROFILE: "CONTACT_PROFILE"
    }

    public static PROFILE_TYPES: any = {
        PRINTING: "PRINTING",
        DISTRIBUTION: "DISTRIBUTION",
        OTHER: "OTHERS"
    }
    public static RFQ_ORDER_TYPE: any = {
        RFQ_NORMAL: "RFQ_NORMAL",
        RFQ_FREE: "RFQ_FREE",
        ORDER_NORMAL: "ORDER_NORMAL",
        ORDER_FREE: "ORDER_FREE"
    }
    public static TAB_TYPES: any = {
        RFQ: {
            PUBLISHED: "RFQ_PUBLISHED",
            ACCEPTANCE: "RFQ_ACCEPTANCE",
            EXPIRED: "RFQ_EXPIRED",
            REJECTED: "RFQ_REJECTED",
            FREE: "RFQ_FREE",
            CONVERTED: "RFQ_CONVERTED",
            CANCELLED:"RFQ_CANCELLED"
        },
        ORDER: {
            ACTIVE: "ORDER_ACTIVE",
            FREE: "ORDER_FREE",
            COMPLETED: "ORDER_COMPLETED",
            CANCELLED: "ORDER_CANCELLED"
        }
    }

    public static ORDER_TYPES: any = {
        ACTIVE: "ACTIVE",
        FREE: "FREE",
        COMPLETED: "COMPLETED",
        CANCELLED: "CANCELLED"
    }
    public static RFQ_TYPES: any = {
        UNPUBLISHED: "UNPUBLISHED",
        PUBLISHED: "PUBLISHED",
        ACCEPTANCE: "ACCEPTANCE",
        EXPIRED: "EXPIRED",
        REJECTED: "REJECTED",
        FREE: "FREE",
        CONVERTED: "CONVERTED",
        CANCELLED: "CANCELLED"
    }

    public static loadingConfig = {
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
    }

    public static language = [
        {
            id: 1,
            i18nCode: 'en',
            localeUtf8Code: 'en_US'
        },
        {
            id: 2,
            i18nCode: 'sv',
            localeUtf8Code: 'sv_SE'
        }
    ]
    public static productionTime = {
        ONE_TO_THREE_DAYS: "ONE_TO_THREE_DAYS",
        THREE_TO_FIVE_DAYS: "THREE_TO_FIVE_DAYS",
        FIVE_TO_SEVEN_DAYS: "FIVE_TO_SEVEN_DAYS",
        SEVEN_TO_ELEVEN_DAYS: "SEVEN_TO_ELEVEN_DAYS",
        TWELVE_TO_SEVENTEEN_DAYS: "TWELVE_TO_SEVENTEEN_DAYS",
        ONE_MONTH: "ONE_MONTH"
    }
    public static DECLINE_REASON = {
        OTHER_SUPPLIER: "OTHER_SUPPLIER",
    }
    public static DECLINE_REASON_RFQ = {
        UPDATION_OF_RFQ_IS_REQUIRED: "RFQ_UPDATION_REQUIRED",
        PRICE_IS_TOO_HIGH: "PRICE_TOO_HIGH",
        DELIVERY_IS_TOO_MUCH_DELAYED: "DELIVERY_TOO_MUCH_DELAYED",
        OTHER_REASONS: "OTHER_REASONS"
    }

    public static allowedFileExt = '.pdf,.xls,.xlsx,.odt,.odp,.ods,.ppt,.pptx,.txt,.doc,.docx,.jpg,.jpeg,.png,.gif,.html,.xml,.csv,.log,.rtf,.zip,.rar,.jar,.svg,.css,.dot,.ott,.psd,.ai,.dwg,.dxf,.htm,.sql,.xlsb,.xltx,.docm,.dotm,.db,.mp3,.mp4,.mov,.avi,.wmv,.wav,.gdoc,.tar,.lst,.idml,.ttf,.otf,.odb,.gz,.bak,.dat,.icc,.dmg,.tif,.eal,.ppp,.crt,.pfb,.ex_,.eps,.gmap,.cfg,.conf,.graffle,.icm,.indd,.pfm,.js'
    public static HISTORY_TYPE = {
        PROPOSAL: 'RFQ_ORDER_CUSTOMER_PROPOSAL',
        INVOICE: 'RFQ_ORDER_CUSTOMER_INVOICE',
        CONFIRMATION: 'RFQ_ORDER_SUPPLIER_CONFIRMATION',
    }
    public static downloadFileName = {
        CUSTOMER_PROPOSAL: 'customer_proposal_',
        CUSTOMER_INVOICE: 'customer_invoice_',
        SUPPLIER_CONFIRMATION: 'supplier_confirmation_',
        DELIVERY_DETAILS: 'delivery_details_',
        ORDER_LIST: 'order_list_report',
        OFFER_LIST: 'offer_list_report',
        MARGIN_LIST: 'margin_list_report',
        DELIVERY_LIST: 'delivery_list_report',
        INVOICE_LIST: 'invoice_list_report',
    }
    public static downloadFileTypeExtension = {
        PDF: '.pdf',
        XLSX: '.xlsx',
    }

    public static priceCalculation = [
        {
            "id":1,
            "code":"LOOSE_COUPON_PRINTING_150",
            "description":"Loose coupon with code, silk coated 150 gm, page size 148x105 with ink as 4+4", 
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"YES",
                "PAPER":"SILK_COATED",
                "PAGE_COUNT":[
                    "1",
                    ""
                ],
                "WEIGHT":[
                    "150"
                ],
                "PRINT_INK":[
                    "4+4"
                ],
                "PAGE_SIZE":"148x105",
                "CALC_BASIS":"UP_TO",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    
                ],
                "EXTRA_OPTIONS":[
                    
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":100,
                    "PRODUCTION_PRICE":1044,
                    "CLEARON_PRICE":1566,
                    "MARGIN_PRICE":522,
                    "PIPELINE_PRICE":1305
                },
                {
                    "START_COPY_VAL":101,
                    "END_COPY_VAL":500,
                    "PRODUCTION_PRICE":1452,
                    "CLEARON_PRICE":2178,
                    "MARGIN_PRICE":726,
                    "PIPELINE_PRICE":1815
                },
                {
                    "START_COPY_VAL":501,
                    "END_COPY_VAL":1000,
                    "PRODUCTION_PRICE":1752,
                    "CLEARON_PRICE":2628,
                    "MARGIN_PRICE":876,
                    "PIPELINE_PRICE":2190
                },
                {
                    "START_COPY_VAL":1000,
                    "END_COPY_VAL":2000,
                    "PRODUCTION_PRICE":1908,
                    "CLEARON_PRICE":2862,
                    "MARGIN_PRICE":954,
                    "PIPELINE_PRICE":2385
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "PRODUCTION_PRICE":2604,
                    "CLEARON_PRICE":3906,
                    "MARGIN_PRICE":1302,
                    "PIPELINE_PRICE":3255
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "PRODUCTION_PRICE":3648,
                    "CLEARON_PRICE":5472,
                    "MARGIN_PRICE":1824,
                    "PIPELINE_PRICE":4560
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":5000,
                    "PRODUCTION_PRICE":3848,
                    "CLEARON_PRICE":5772,
                    "MARGIN_PRICE":1924,
                    "PIPELINE_PRICE":4810
                },
                {
                    "START_COPY_VAL":5001,
                    "END_COPY_VAL":10000,
                    "PRODUCTION_PRICE":4116,
                    "CLEARON_PRICE":6174,
                    "MARGIN_PRICE":2058,
                    "PIPELINE_PRICE":5145
                },
                {
                    "START_COPY_VAL":10001,
                    "END_COPY_VAL":15000,
                    "PRODUCTION_PRICE":5172,
                    "CLEARON_PRICE":7758,
                    "MARGIN_PRICE":2586,
                    "PIPELINE_PRICE":6465
                },
                {
                    "START_COPY_VAL":15001,
                    "END_COPY_VAL":20000,
                    "PRODUCTION_PRICE":8592,
                    "CLEARON_PRICE":12888,
                    "MARGIN_PRICE":4296,
                    "PIPELINE_PRICE":10740
                },
                {
                    "START_COPY_VAL":20001,
                    "END_COPY_VAL":25000,
                    "PRODUCTION_PRICE":10416,
                    "CLEARON_PRICE":15624,
                    "MARGIN_PRICE":5208,
                    "PIPELINE_PRICE":13020
                },
                {
                    "START_COPY_VAL":25001,
                    "END_COPY_VAL":35000,
                    "PRODUCTION_PRICE":8688,
                    "CLEARON_PRICE":13032,
                    "MARGIN_PRICE":4344,
                    "PIPELINE_PRICE":10860
                },
                {
                    "START_COPY_VAL":35001,
                    "END_COPY_VAL":40000,
                    "PRODUCTION_PRICE":9480,
                    "CLEARON_PRICE":14220,
                    "MARGIN_PRICE":4740,
                    "PIPELINE_PRICE":11850
                },
                {
                    "START_COPY_VAL":40001,
                    "END_COPY_VAL":45000,
                    "PRODUCTION_PRICE":10512,
                    "CLEARON_PRICE":15768,
                    "MARGIN_PRICE":5256,
                    "PIPELINE_PRICE":13140
                },
                {
                    "START_COPY_VAL":45001,
                    "END_COPY_VAL":50000,
                    "PRODUCTION_PRICE":11328,
                    "CLEARON_PRICE":16992,
                    "MARGIN_PRICE":5664,
                    "PIPELINE_PRICE":14160
                }
            ]
        },
        {
            "id":2,
            "code":"LOOSE_COUPON_PRINTING_150",
            "description":"Loose coupon with code, silk coated 150 gm, page size 120x65 with ink as 4+4", 
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"YES",
                "PAPER":"SILK_COATED",
                "PAGE_COUNT":[
                    "1",
                    ""
                ],
                "WEIGHT":[
                    "150"
                ],
                "PRINT_INK":[
                    "4+4"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"UP_TO",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    
                ],
                "EXTRA_OPTIONS":[
                    
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":100,
                    "PRODUCTION_PRICE":1044,
                    "CLEARON_PRICE":1566,
                    "MARGIN_PRICE":522,
                    "PIPELINE_PRICE":1305
                },
                {
                    "START_COPY_VAL":101,
                    "END_COPY_VAL":500,
                    "PRODUCTION_PRICE":1452,
                    "CLEARON_PRICE":2178,
                    "MARGIN_PRICE":726,
                    "PIPELINE_PRICE":1815
                },
                {
                    "START_COPY_VAL":501,
                    "END_COPY_VAL":1000,
                    "PRODUCTION_PRICE":1620,
                    "CLEARON_PRICE":2430,
                    "MARGIN_PRICE":810,
                    "PIPELINE_PRICE":2025
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":2000,
                    "PRODUCTION_PRICE":1788,
                    "CLEARON_PRICE":2682,
                    "MARGIN_PRICE":894,
                    "PIPELINE_PRICE":2235
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "PRODUCTION_PRICE":1956,
                    "CLEARON_PRICE":2934,
                    "MARGIN_PRICE":978,
                    "PIPELINE_PRICE":2445
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "PRODUCTION_PRICE":2112,
                    "CLEARON_PRICE":3168,
                    "MARGIN_PRICE":1056,
                    "PIPELINE_PRICE":2640
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":5000,
                    "PRODUCTION_PRICE":2376,
                    "CLEARON_PRICE":3564,
                    "MARGIN_PRICE":1188,
                    "PIPELINE_PRICE":2970
                },
                {
                    "START_COPY_VAL":5001,
                    "END_COPY_VAL":10000,
                    "PRODUCTION_PRICE":3612,
                    "CLEARON_PRICE":5418,
                    "MARGIN_PRICE":1806,
                    "PIPELINE_PRICE":4515
                },
                {
                    "START_COPY_VAL":10001,
                    "END_COPY_VAL":15000,
                    "PRODUCTION_PRICE":4392,
                    "CLEARON_PRICE":6588,
                    "MARGIN_PRICE":2196,
                    "PIPELINE_PRICE":5490
                },
                {
                    "START_COPY_VAL":15001,
                    "END_COPY_VAL":20000,
                    "PRODUCTION_PRICE":4980,
                    "CLEARON_PRICE":7470,
                    "MARGIN_PRICE":2490,
                    "PIPELINE_PRICE":6225
                },
                {
                    "START_COPY_VAL":20001,
                    "END_COPY_VAL":25000,
                    "PRODUCTION_PRICE":5832,
                    "CLEARON_PRICE":8748,
                    "MARGIN_PRICE":2916,
                    "PIPELINE_PRICE":7290
                },
                {
                    "START_COPY_VAL":25001,
                    "END_COPY_VAL":35000,
                    "PRODUCTION_PRICE":6864,
                    "CLEARON_PRICE":10296,
                    "MARGIN_PRICE":3432,
                    "PIPELINE_PRICE":8580
                },
                {
                    "START_COPY_VAL":35001,
                    "END_COPY_VAL":40000,
                    "PRODUCTION_PRICE":7392,
                    "CLEARON_PRICE":11088,
                    "MARGIN_PRICE":3696,
                    "PIPELINE_PRICE":9240
                },
                {
                    "START_COPY_VAL":40001,
                    "END_COPY_VAL":45000,
                    "PRODUCTION_PRICE":8184,
                    "CLEARON_PRICE":12276,
                    "MARGIN_PRICE":4092,
                    "PIPELINE_PRICE":10230
                },
                {
                    "START_COPY_VAL":45001,
                    "END_COPY_VAL":50000,
                    "PRODUCTION_PRICE":8724,
                    "CLEARON_PRICE":13086,
                    "MARGIN_PRICE":4362,
                    "PIPELINE_PRICE":10905
                }
            ]
        },
        {
            "id":3,
            "code":"LOOSE_COUPON_PRINTING_120_AND_130",
            "description":"Loose coupon with code, silk coated 120/130 gm, page size 148x105 with ink as 4+4, Pad Gluing as Binding Options, Hole Punching and Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"YES",
                "PAPER":"SILK_COATED",
                "PAGE_COUNT":[
                    "25"
                ],
                "WEIGHT":[
                    "120",
                    "130"
                ],
                "PRINT_INK":[
                    "4+4"
                ],
                "PAGE_SIZE":"148x105",
                "CALC_BASIS":"UP_TO",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":40,
                    "PRODUCTION_PRICE":2268,
                    "CLEARON_PRICE":3402,
                    "MARGIN_PRICE":1134,
                    "PIPELINE_PRICE":2835
                },
                {
                    "START_COPY_VAL":41,
                    "END_COPY_VAL":200,
                    "PRODUCTION_PRICE":3528,
                    "CLEARON_PRICE":5292,
                    "MARGIN_PRICE":1764,
                    "PIPELINE_PRICE":4410
                },
                {
                    "START_COPY_VAL":201,
                    "END_COPY_VAL":400,
                    "PRODUCTION_PRICE":4572,
                    "CLEARON_PRICE":6858,
                    "MARGIN_PRICE":2286,
                    "PIPELINE_PRICE":5715
                },
                {
                    "START_COPY_VAL":401,
                    "END_COPY_VAL":600,
                    "PRODUCTION_PRICE":5652,
                    "CLEARON_PRICE":8478,
                    "MARGIN_PRICE":2826,
                    "PIPELINE_PRICE":7065
                },
                {
                    "START_COPY_VAL":601,
                    "END_COPY_VAL":800,
                    "PRODUCTION_PRICE":6972,
                    "CLEARON_PRICE":10458,
                    "MARGIN_PRICE":3486,
                    "PIPELINE_PRICE":8715
                },
                {
                    "START_COPY_VAL":801,
                    "END_COPY_VAL":1000,
                    "PRODUCTION_PRICE":8400,
                    "CLEARON_PRICE":12600,
                    "MARGIN_PRICE":4200,
                    "PIPELINE_PRICE":10500
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1200,
                    "PRODUCTION_PRICE":9504,
                    "CLEARON_PRICE":14256,
                    "MARGIN_PRICE":4752,
                    "PIPELINE_PRICE":11880
                },
                {
                    "START_COPY_VAL":1201,
                    "END_COPY_VAL":1400,
                    "PRODUCTION_PRICE":8856,
                    "CLEARON_PRICE":13284,
                    "MARGIN_PRICE":4428,
                    "PIPELINE_PRICE":11070
                },
                {
                    "START_COPY_VAL":1401,
                    "END_COPY_VAL":1600,
                    "PRODUCTION_PRICE":11076,
                    "CLEARON_PRICE":16614,
                    "MARGIN_PRICE":5538,
                    "PIPELINE_PRICE":13845
                },
                {
                    "START_COPY_VAL":1601,
                    "END_COPY_VAL":1800,
                    "PRODUCTION_PRICE":12372,
                    "CLEARON_PRICE":18558,
                    "MARGIN_PRICE":6186,
                    "PIPELINE_PRICE":15464
                },
                {
                    "START_COPY_VAL":1801,
                    "END_COPY_VAL":2000,
                    "PRODUCTION_PRICE":13356,
                    "CLEARON_PRICE":20034,
                    "MARGIN_PRICE":6678,
                    "PIPELINE_PRICE":16695
                }
            ]
        },
        {
            "id":4,
            "code":"LOOSE_COUPON_PRINTING_120_AND_130",
            "description":"Loose coupon with code, silk coated 120/130 gm, page size 120x65 with ink as 4+4, Pad Gluing as Binding Options, Hole Punching and Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"YES",
                "PAPER":"SILK_COATED",
                "PAGE_COUNT":[
                    "25"
                ],
                "WEIGHT":[
                    "120",
                    "130"
                ],
                "PRINT_INK":[
                    "4+4"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"UP_TO",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":40,
                    "PRODUCTION_PRICE":2136,
                    "CLEARON_PRICE":3204,
                    "MARGIN_PRICE":1068,
                    "PIPELINE_PRICE":2670
                },
                {
                    "START_COPY_VAL":41,
                    "END_COPY_VAL":200,
                    "PRODUCTION_PRICE":3408,
                    "CLEARON_PRICE":5112,
                    "MARGIN_PRICE":1704,
                    "PIPELINE_PRICE":4260
                },
                {
                    "START_COPY_VAL":201,
                    "END_COPY_VAL":400,
                    "PRODUCTION_PRICE":3996,
                    "CLEARON_PRICE":5994,
                    "MARGIN_PRICE":1998,
                    "PIPELINE_PRICE":4995
                },
                {
                    "START_COPY_VAL":401,
                    "END_COPY_VAL":600,
                    "PRODUCTION_PRICE":4800,
                    "CLEARON_PRICE":7200,
                    "MARGIN_PRICE":2400,
                    "PIPELINE_PRICE":6000
                },
                {
                    "START_COPY_VAL":601,
                    "END_COPY_VAL":800,
                    "PRODUCTION_PRICE":5844,
                    "CLEARON_PRICE":8766,
                    "MARGIN_PRICE":2922,
                    "PIPELINE_PRICE":7305
                },
                {
                    "START_COPY_VAL":801,
                    "END_COPY_VAL":1000,
                    "PRODUCTION_PRICE":6936,
                    "CLEARON_PRICE":10404,
                    "MARGIN_PRICE":3468,
                    "PIPELINE_PRICE":8670
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1200,
                    "PRODUCTION_PRICE":7692,
                    "CLEARON_PRICE":11538,
                    "MARGIN_PRICE":3846,
                    "PIPELINE_PRICE":9615
                },
                {
                    "START_COPY_VAL":1201,
                    "END_COPY_VAL":1400,
                    "PRODUCTION_PRICE":8436,
                    "CLEARON_PRICE":12654,
                    "MARGIN_PRICE":4218,
                    "PIPELINE_PRICE":10545
                },
                {
                    "START_COPY_VAL":1401,
                    "END_COPY_VAL":1600,
                    "PRODUCTION_PRICE":9192,
                    "CLEARON_PRICE":13788,
                    "MARGIN_PRICE":4596,
                    "PIPELINE_PRICE":11490
                },
                {
                    "START_COPY_VAL":1601,
                    "END_COPY_VAL":1800,
                    "PRODUCTION_PRICE":10188,
                    "CLEARON_PRICE":15282,
                    "MARGIN_PRICE":5094,
                    "PIPELINE_PRICE":12735
                },
                {
                    "START_COPY_VAL":1801,
                    "END_COPY_VAL":2000,
                    "PRODUCTION_PRICE":10932,
                    "CLEARON_PRICE":16398,
                    "MARGIN_PRICE":5466,
                    "PIPELINE_PRICE":13665
                }
            ]
        },
        {
            "id":5,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 148x210, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"148x210",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0012,
                    "M_VAL":6.6,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0006,
                    "M_VAL":5.7,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0004,
                    "M_VAL":5.3,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":4.55,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":6,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 148x210, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"148x210",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0012,
                    "M_VAL":6.6,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0006,
                    "M_VAL":5.7,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0004,
                    "M_VAL":5.3,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":4.55,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":7,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 148x210, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Horizontal Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"148x210",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "HORIZONTAL_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0012,
                    "M_VAL":6.6,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0006,
                    "M_VAL":5.7,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0004,
                    "M_VAL":5.3,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":4.55,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.5,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":8,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 148x210, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Horizontal Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"148x210",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "HORIZONTAL_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0012,
                    "M_VAL":6.6,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0006,
                    "M_VAL":5.7,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0004,
                    "M_VAL":5.3,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":4.55,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":9,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 148x210, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Angle Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"148x210",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "ANGLE_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0012,
                    "M_VAL":6.6,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0006,
                    "M_VAL":5.7,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0004,
                    "M_VAL":5.3,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":4.55,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":10,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 148x210, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Angle Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"148x210",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "ANGLE_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0032,
                    "M_VAL":8.6,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0012,
                    "M_VAL":6.6,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0006,
                    "M_VAL":5.7,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0004,
                    "M_VAL":5.3,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":4.55,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":4.25,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":11,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 120x65, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":4.05,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.6,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.2,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":3.05,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":12,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 120x65, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":4.05,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.6,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.2,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                     "K_VAL":-0.00015,
                    "M_VAL":3.05,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":13,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 120x65, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Horizontal Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "HORIZONTAL_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":4.05,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.6,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.2,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                     "K_VAL":-0.00015,
                    "M_VAL":3.05,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":14,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 120x65, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Horizontal Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "HORIZONTAL_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":4.05,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.6,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.2,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":3.05,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":15,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 120x65, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Angle Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "ANGLE_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":4.05,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.6,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.2,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":3.05,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":16,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 120x65, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Angle Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"120x65",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "ANGLE_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0018,
                    "M_VAL":5.15,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":4.05,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.6,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.2,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":-0.00015,
                    "M_VAL":3.05,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":-7.5E-05,
                    "M_VAL":2.75,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":17,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 105x148, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"105x148",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":3.95,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.5,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":18,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 105x148, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"105x148",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":3.95,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.5,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.1,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":625,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":19,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 105x148, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Horizontal Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"105x148",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "HORIZONTAL_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":3.95,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.5,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.1,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":750,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":20,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 105x148, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Horizontal Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"105x148",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "HORIZONTAL_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":3.95,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.5,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.1,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":1375,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":21,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+0, page size 105x148, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Angle Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+0"
                ],
                "PAGE_SIZE":"105x148",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "ANGLE_PERFORATION",
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":3.95,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.5,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.1,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":3500,
                    "AFTER_500_COPY":0.50,
                    "COPIES_VAL":"INPUT"
                }
            ]
        },
        {
            "id":22,
            "code":"GLUED_COUPON_PRINTING_120",
            "description":"Glued coupon with silk coated 120 gm with ink as 4+1/4+4, page size 105x148, Pad Gluing as Binding Options and Hole Punching, Cardboard Bottom, Angle Perforation as Extra Options",
            "variables":{
                "PRINT_TYPE":"COUPON_PRINTING",
                "GENERATE_COUPON":"NO",
                "PAPER":"SILK_COATED",
                "WEIGHT":[
                    "120"
                ],
                "PRINT_INK":[
                    "4+1",
                    "4+4"
                ],
                "PAGE_SIZE":"105x148",
                "CALC_BASIS":"PER_COPY_WITH_K_AND_M_AND_L",
                "PRINTING_OPTIONS":[
                    
                ],
                "BINDING_OPTIONS":[
                    "PAD_GLUING"
                ],
                "EXTRA_OPTIONS":[
                    "HOLE_PUNCHING",
                    "CARDBOARD_BOTTOM",
                    "ANGLE_PERFORATION"
                ]
            },
            "pricings":[
                {
                    "START_COPY_VAL":1,
                    "END_COPY_VAL":500,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":500
                },
                {
                    "START_COPY_VAL":500,
                    "END_COPY_VAL":1000,
                    "K_VAL":-0.0017,
                    "M_VAL":4.95,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1001,
                    "END_COPY_VAL":1500,
                    "K_VAL":-0.0007,
                    "M_VAL":3.95,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":1501,
                    "END_COPY_VAL":2000,
                    "K_VAL":-0.0004,
                    "M_VAL":3.5,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":2001,
                    "END_COPY_VAL":3000,
                    "K_VAL":-0.0002,
                    "M_VAL":3.1,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":3001,
                    "END_COPY_VAL":4000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":4001,
                    "END_COPY_VAL":6000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                },
                {
                    "START_COPY_VAL":6001,
                    "END_COPY_VAL":8000,
                    "K_VAL":0,
                    "M_VAL":2.5,
                    "UP_TO_500_COPY":4125,
                    "AFTER_500_COPY":1,
                    "COPIES_VAL":"INPUT"
                }
            ]
        }
    ]
}
